/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./Menu.css";
import "../../index.css";

export default function Menu() {
  return (
    <div className="menu--closed" id="menu">
      <div className="menu__content">
        <ul>
          <li>
            <a
              onClick={() => {
                document
                  .getElementById("hero")
                  .scrollIntoView({ behavior: "smooth" });
                const menu = document.getElementById("menu");
                menu.classList.add("menu--closed");
                menu.classList.remove("menu--open");
              }}
            >
              Home
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                document
                  .getElementById("about")
                  .scrollIntoView({ behavior: "smooth" });
                const menu = document.getElementById("menu");
                menu.classList.add("menu--closed");
                menu.classList.remove("menu--open");
              }}
            >
              About
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                document
                  .getElementById("portfolio")
                  .scrollIntoView({ behavior: "smooth" });
                const menu = document.getElementById("menu");
                menu.classList.add("menu--closed");
                menu.classList.remove("menu--open");
              }}
            >
              Portfolio
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                document
                  .getElementById("contact")
                  .scrollIntoView({ behavior: "smooth" });
                const menu = document.getElementById("menu");
                menu.classList.add("menu--closed");
                menu.classList.remove("menu--open");
              }}
            >
              Contact
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
