/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useEffect, useState } from "react";

import logo from "../../assets/Bl4ckdrake.png";
import testLogo from "../../assets/emmegilogo-light.png";

import "./Header.css";
import "../../index.css";
import i18n from "../../util/i18n";

export default function Header() {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  var menuBtn = (
    <div id="menuBtn">
      <div id="menuBtn__lineTop"></div>
      <div id="menuBtn__lineBottom"></div>
    </div>
  );

  const toggleMenu = () => {
    const menu = document.getElementById("menu");
    //const hamburger = document.getElementById("hamburger");
    if (menu.classList.contains("menu--closed")) {
      menu.classList.remove("menu--closed");
      menu.classList.add("menu--open");
      //whamburger.classList.add("hamburger--open");
    } else {
      menu.classList.remove("menu--open");
      menu.classList.add("menu--closed");
      //hamburger.classList.remove("hamburger--open");
    }
  };

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const fullHeight = document.documentElement.scrollHeight;
    const currentPosition = window.scrollY;

    const scrollPercentage =
      (currentPosition / (fullHeight - windowHeight)) * 100;

    setScrollPercentage(scrollPercentage);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      style={
        scrollPercentage === 0
          ? { backdropFilter: "none" }
          : { backdropFilter: "blur(5px)" }
      }
      className="header"
    >
      <div className="logo">
        <img src={testLogo} alt="logo" />
      </div>
      <div className="actions">
        <a
          onClick={() => {
            i18n.changeLanguage("it");
          }}
        >
          IT
        </a>
        |
        <a
          onClick={() => {
            i18n.changeLanguage("en");
          }}
        >
          EN
        </a>
        <a onClick={toggleMenu}>{menuBtn}</a>
      </div>
    </div>
  );
}
